$font-size-20: 20px;
$border-grey: 1px solid rgba(255, 255, 255, 0.5);
$letter-spacing-08: 0.8px;
$grey: #b8b8b8;
$darkgrey: rgb(36, 36, 36);
$grey-transp: rgb(217, 217, 217, 0.1);
$cyanLogo: #5cedc2;
$purpleLogo: #e453bc;
$cyanLogoFilter: brightness(0) saturate(100%) invert(94%) sepia(7%)
  saturate(3213%) hue-rotate(93deg) brightness(100%) contrast(94%);
$gradient: linear-gradient(90.21deg, #aa367c -5.91%, #092db2 111.58%);
$mobile-title: 2rem;

@mixin mobile-text {
  font-size: 0.8rem !important;
  text-align: left !important;
}

@mixin zeroStart {
  margin: 0;
  padding: 0;
}
@mixin iconSize {
  width: 42px;
  height: 42px;
}
@mixin transition {
  transition: 0.3s ease-in-out;
}
@mixin transition-icon {
  transition: all 300ms linear;
}
@mixin repeatedProperty {
  background-color: $cyanLogo;
  top: 0;
  left: 0;
}
@mixin repeatedProperty2 {
  background-color: #fff;
  height: 2px;
}
@mixin repeatedProperty3 {
  font-weight: 700;
  letter-spacing: 0.8;
}
@mixin parrafo {
  color: $grey;
  font-size: 18px;
  letter-spacing: $letter-spacing-08;
  line-height: 1.5em;
}

@mixin send-btn {
  .send-btn {
    background-color: #fff;
    @include transition;
    font-weight: 700;
    border: none;
    border-radius: 0;
    position: relative;
    color: #000;
    span {
      z-index: 1;
      position: relative;
    }
  }
  .send-btn::before {
    content: "";
    background: $cyanLogo;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    @include transition;
  }
  .send-btn:hover::before {
    width: 100%;
  }
}

@mixin svg-animation {
  @keyframes updown {
    0% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(-20px);
    }
  }
}

@mixin arrowright {
  color: #fff;
  font-weight: 700;
  font-size: $font-size-20;
  margin-bottom: 0px;
  letter-spacing: $letter-spacing-08;
  display: flex;
  align-items: center;
  @include max-extrasmall {
    margin-top: 4vh;
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
  }
  svg {
    font-size: 25px;
    margin-left: 10px;
    @include transition;
    line-height: 1;
  }
}
@mixin arrowright-hover {
  a:hover {
    svg {
      margin-left: 25px;
    }
  }
}

// MEDIA QUERYs

@mixin max-extrasmall {
  @media only screen and (max-width: 576px) {
    @content;
  }
}

@mixin max-medium {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin max-767-H {
  @media only screen and (max-height: 767px) {
    @content;
  }
}

@mixin b-576-992 {
  @media only screen and (min-width: 576px) and (max-width: 992px) {
    @content;
  }
}

@mixin max-large-H {
  @media only screen and (max-height: 992px) {
    @content;
  }
}

@mixin max-large {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin b-992-1200 {
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin max-1200-H {
  @media only screen and (max-height: 1200px) {
    @content;
  }
}

@mixin min-extralarge {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

@mixin max-extralarge {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin screen-1400 {
  @media only screen and (max-width: 1400px) {
    @content;
  }
}

// SOCIAL NETWORK ICONS
.social-icon {
  display: inline-block;
  a {
    @include iconSize;
    background: $grey-transp;
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: $border-grey;
    img {
      width: 40%;
      z-index: 1;
      @include transition;
    }
  }
  a::before {
    content: "";
    @include iconSize;
    position: absolute;
    background-color: $cyanLogo;
    border-radius: 50%;
    transform: scale(0);
    @include transition;
  }
  a:hover::before {
    transform: scale(1);
  }
  a:hover {
    img {
      filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
        hue-rotate(346deg) brightness(95%) contrast(86%);
    }
  }
}
