@import "../../../variables";

.proj-imgbx {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 24px;
  height: 25vh;
  max-height: 265px;
  min-height: 214px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  @include max-767-H {
    max-height: 214px !important;
  }
  @include max-1200-H {
    height: 40vh;
    max-height: 227px;
  }
  .proj-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 90%;
    h4 {
      font-size: 30px;
      font-weight: 700;
      letter-spacing: $letter-spacing-08;
      line-height: 1.1em;
    }
    span {
      font-style: italic;
      font-weight: 400;
      font-size: 15px;
      letter-spacing: $letter-spacing-08;
    }
    p {
      color: white;
      font-size: 3rem;
      margin: 0px;
      width: 10%;
      height: 3rem !important;
      padding: 0px;
      @include transition-icon;
    }
    p:hover {
      transform: scale(1.5);
    }
  }
}

.web::before {
  background: $gradient;
}

.graphic::before {
  background: linear-gradient(
    90.21deg,
    rgb(36, 36, 36) -5.91%,
    #5cedc2 111.58%
  );
}

.industrial::before {
  background: linear-gradient(
    90.21deg,
    rgb(36, 36, 36) -5.91%,
    #e453bc 111.58%
  );
}

.proj-imgbx::before {
  content: "";
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-imgbx:hover {
  .proj-txtx {
    top: 50%;
    opacity: 1;
  }
}
