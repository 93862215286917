@import "../../variables";

.footer {
  padding: 0 0 50px 0;
  .logo-footer {
    width: 10rem;
  }
  p {
    color: $grey;
    letter-spacing: 0.5px;
  }
  .mod {
    font-size: 0.8rem !important;
    padding-top: 20px;
  }
  .credits {
    font-weight: 400;
    font-size: 1rem;

    margin-top: 20px;
  }
  a {
    color: white;
  }
}
