@import "../../variables";

:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.faq {
  h2 {
    font-size: 45px;
    font-weight: 700;
    @include max-extrasmall {
      font-size: $mobile-title;
    }
  }
  p {
    @include parrafo;
    margin: 14px auto 50px auto;
    max-width: 600px;
    @include max-extrasmall {
      @include mobile-text;
      margin-bottom: 25px;
    }
    @include screen-1400 {
      font-size: 1rem;
    }
  }
  a {
    color: white;
    text-decoration: none;
  }
  .cv-link:hover {
    color: $cyanLogo;
  }
  .accordion {
    --bs-accordion-bg: transparent !important;
    --bs-accordion-border-width: 0px !important;
    .accordion-collapse,
    .accordion-button {
      color: white;
    }
    .accordion-body {
      font-weight: 400;
    }
    .accordion-button.collapsed {
      font-weight: 500;
      font-size: 18px;
      color: white;
    }
    .accordion-button.collapsed:hover {
      font-weight: 700;
    }
    .accordion-button.collapsed::after {
      filter: brightness(100);
    }
    .accordion-button {
      background-color: transparent;
      font-weight: 700;
      font-size: 24px;
      color: $cyanLogo;
      border-bottom: 1px solid white;
    }
    .accordion-button::after {
      filter: $cyanLogoFilter;
    }
  }
  .list-group-item {
    color: white;
    background-color: transparent;
    border: none !important;
  }
  .faq-content {
    .clients,
    .jobs {
      div {
        display: flex;
        justify-content: center;
        padding-block: 12px;
        img {
          max-width: 100px;
          min-width: 55px;
          max-height: 30px;
        }
        img:hover {
          filter: $cyanLogoFilter;
          transform: scale(1.05);
        }
      }
    }
    .hobbie-bx {
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        max-width: 150px;
        @include transition-icon;
      }
      p {
        max-width: 80%;
        margin-bottom: 1.2rem;
        font-weight: 400;
      }
      @include max-medium {
        flex-direction: row !important;
        p {
          margin-block: 0px;
          margin-left: 1rem;
        }
      }
    }
    .hobbie-bx:hover {
      background-color: $grey-transp;
      img {
        filter: $cyanLogoFilter;
        transform: scale(1.05);
      }
    }
    .study-bx {
      .where {
        font-size: 12px;
      }
    }
    .study-bx:hover {
      background-color: $grey-transp;
      h5 {
        color: $cyanLogo;
      }
      h6 {
        color: $purpleLogo;
      }
    }
    p {
      color: white;
      font-size: 1rem;
      font-weight: 400;
      margin-block: 0.8rem;
    }
    .toRecomm {
      margin-top: 1rem;
      @include arrowright;
    }
    @include arrowright-hover;
  }
}
