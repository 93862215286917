@import "../../variables";

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: 0.32s ease-in-out;
  a.navbar-brand {
    width: 9%;
    @include max-extrasmall {
      margin-left: 2rem !important;
    }
    @include max-large {
      width: 15vw;
      min-width: 100px;
    }
  }
  .navbar-nav {
    .navbar-link.navbar-nav,
    .dropdown-toggle.nav-link {
      font-weight: 400;
      letter-spacing: $letter-spacing-08;
      color: white !important;
      padding: 0 25px;
      font-size: 18px;
      opacity: 0.75;
      @include b-992-1200 {
        font-size: 12px;
      }
    }
    .navbar-link.navbar-nav:hover,
    .dropdown-toggle.nav-link:hover,
    .navbar-link.navbar-nav.active {
      opacity: 1;
    }
  }
}

.dropdown-menu.show {
  background-color: $darkgrey;
  padding-bottom: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border: none;
  .dropdown-item {
    color: white;
    text-align: right;
  }
  .dropdown-item:hover {
    background-color: $cyanLogo;
    color: black;
  }
  .dropdown-divider {
    border-color: $purpleLogo;
  }
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
  @include max-extrasmall {
    padding-block: 5px;
  }
}

.navbar-text {
  display: flex;
  align-items: center;
  button {
    font-weight: 700;
    color: #fff;
    border: 1px solid #fff;
    padding: 18px 34px;
    font-size: 18px;
    margin-left: 18px;
    position: relative;
    background-color: transparent;
    @include transition;
    span {
      z-index: 1;
    }
    @include b-992-1200 {
      font-size: 14px;
    }
  }
  button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    @include repeatedProperty;
    z-index: -1;
    @include transition;
  }
  button:hover {
    color: #121212;
  }
  button:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

nav.navbar {
  .navbar-toggler:active,
  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }
  .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    @include transition-icon;
    top: -2px;
  }
  .navbar-toggler-icon:focus {
    border-bottom: 2px solid #fff;
  }
  .navbar-toggler-icon:after,
  .navbar-toggler-icon::before {
    width: 24px;
    position: absolute;
    height: 2px;
    @include repeatedProperty;
    content: "";
    z-index: 2;
    @include transition-icon;
  }
  .navbar-toggler-icon:after {
    top: 8px;
  }
  .navbar-toggler[aria-expanded="true"] {
    .navbar-toggler-icon:after {
      transform: rotate(45deg);
      @include repeatedProperty2;
    }
    .navbar-toggler-icon:before {
      transform: translateY(8px) rotate(-45deg);
      @include repeatedProperty2;
    }
    .navbar-toggle-icon {
      border-color: transparent;
    }
  }
  .navbar-collapse {
    @include max-large {
      background-color: $darkgrey !important;
      background-image: url("../../assets/logo/A.svg");
      background-position: center left 2rem;
      background-repeat: no-repeat;
      background-size: 30%;
      text-align: right !important;
      padding-block: 2rem !important;
      .aboutMe {
        padding-inline: 0px !important;
      }
      .nav-link {
        padding-block: 0.5rem !important;
      }
      .navbar-text {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 25px;
        .social-icon {
          order: 3;
          a {
            margin-inline: 0.5rem;
          }
        }
      }
      button {
        border: none;
        padding-inline: 0px;
        margin-left: 0px;
      }
      button:hover {
        color: $cyanLogo;
      }
    }
  }
}
