@import "../../variables";

.modal-centered {
  text-align: center !important;
  .title-success {
    margin-bottom: 1rem;
  }
  .okay-btn {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  @include send-btn;
}
