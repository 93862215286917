@import "./variables";

// Fonts
@font-face {
  font-family: Centra;
  src: url(./assets/font/CentraNo2-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url(./assets/font/CentraNo2-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url(./assets/font/CentraNo2-Book.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Chathura;
  src: url(./assets/font/Chathura-Regular.ttf);
  font-weight: 400;
}

* {
  @include zeroStart;
  box-sizing: border-box; //no cambia tamaños por su padding
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  margin: 0;
  overflow-x: hidden; //que nunca ponga barra de desplazamiento
  position: relative;
  color: #fff !important;
  font-family: "Centra", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include zeroStart;
    line-height: normal;
  }
  p,
  a,
  li,
  button,
  ul {
    @include zeroStart;
    line-height: normal;
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  img {
    width: 100%;
    height: auto;
  }
  button {
    border: 0;
    background-color: transparent;
  }
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
