@import "../../variables";

.contact {
  padding: 115px 0 200px 0;
  @include max-extrasmall {
    padding-top: 25px;
    padding-bottom: 70px;
    padding-inline: 8px;
  }
  .station {
    img {
      animation-fill-mode: none;
      width: 92%;
    }
  }
  h2 {
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  form {
    input,
    textarea {
      width: 100%;
      background-color: transparent;
      border: 1px solid white;
      color: #fff;
      margin: 0 0 8px 0;
      padding: 18px 26px;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: $letter-spacing-08;
      @include transition;
    }
    input::placeholder,
    textarea::placeholder {
      font-size: 1rem;
      font-weight: 400;
      color: #fff;
    }
    input:focus::placeholder,
    textarea:focus::placeholder {
      color: $grey;
      opacity: 0.5;
    }
    .send-btn {
      padding: 14px 48px;
      font-size: 18px;
      margin-top: 25px;
    }
    @include send-btn;
    .thanks-btn {
      background-color: $cyanLogo !important;
      font-weight: 700;
      padding: 14px 48px;
      font-size: 18px;
      margin-top: 25px;
      border-radius: 0;
      position: relative;
      color: #000;
      span {
        z-index: 1;
        position: relative;
      }
    }
  }
}
.please {
  color: orange;
}
