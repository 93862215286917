@import "../../variables";

.banner {
  margin-top: 0;
  margin-bottom: 10vh;
  padding: 25vh 0 0vh 0;
  height: 100vh;
  @include max-extrasmall {
    padding-top: 15vh;
    padding-bottom: 5vh;
  }
  .tagline {
    @include repeatedProperty3;
    padding: 8px 10px;
    background: $gradient;
    border: $border-grey;
    font-size: $font-size-20;
    margin-bottom: 1rem;
    display: inline-block;
  }
  h1 {
    font-size: 65px;
    @include repeatedProperty3;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
    @include max-extrasmall {
      font-size: $mobile-title !important;
    }
    @include screen-1400 {
      font-size: 50px;
    }
  }
  p {
    z-index: 999;
    @include parrafo;
    width: 96%;
    max-width: 576px;
    @include screen-1400 {
      font-size: 1rem;
    }
    @include max-extrasmall {
      @include mobile-text;
    }
  }
  .toConnect {
    margin-top: 60px;
    @include arrowright;
  }
  @include arrowright-hover;
  .ship {
    animation: updown 3s linear infinite;
  }
  @include svg-animation;
  .ship-container {
    display: flex;
    align-items: flex-start;
    @include max-extrasmall {
      .ship {
        width: 80vw;
      }
    }
    @include max-large {
      justify-content: center !important;
    }
  }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

.arrow-down {
  text-align: center;
  padding-top: 10%;
  img {
    width: 2rem;
    @include transition;
  }
  img:hover {
    margin-top: 6px;
  }
  @include b-576-992 {
    display: none !important;
  }
  @include max-767-H {
    display: none !important;
  }
}
